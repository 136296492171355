@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@200;300;400;500;600;700&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	cursor: none;
}

html {
	overflow-x: hidden;
	
	--backgroundColor: #020408;
	background-color: var(--backgroundColor);
}

body {
  	text-align: center;
  	font-family: 'Inter', 'Rubik', 'Arial';
  	position: relative;
  	overflow: hidden;
}

h1 {
  font-weight: bold;
}

h2 {
  font-weight: 600;
}

main {
	min-height: 100vh;
	background: var(--backgroundColor);
	display: flex;
	align-items: center;
	justify-content: center;
}

.cursor {
	position: fixed;
	left: 0;
	top: 0;
	width: 32px;
	height: 32px;
	border-radius: 100%;
	mix-blend-mode: difference;
	background-color: white;
	z-index: 999999;
	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	pointer-events: none;

	animation: all 0.3 ease;
}

@media only screen and (max-width: 768px) {
	* {
		cursor: initial;
	}

	.cursor {
		display: none;
	}
}

.footer {
	background-color: var(--backgroundColor);
	padding: 50px;
	color: white;
	font-size: 18px;
	font-weight: 400;
}

.footer h3 {
	font-size: inherit;
	font-weight: inherit;
}