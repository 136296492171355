.difference {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    mix-blend-mode: difference;
}

.difference .socials {
    position: absolute;
    top: 45px;
    right: 30px;
    justify-self: flex-end;
    mix-blend-mode: difference;
}

.difference .socials img {
    mix-blend-mode: difference;
    margin: 0px 10px 0px 10px;
}

.Fixed {
	position: fixed;
    display: flex;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    z-index: 9999;
}

.Fixed .title {
    justify-self: center;
    left: 0;
    top: 30px;
    z-index: 9999;
}

.Fixed .title .name {
    left: 0;
    margin-top: 30px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 61px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    z-index: 20;
    
}

.Fixed .title .secondary {
    z-index: 20;
    left: 0;
    margin-top: -10px;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16.3px;
    line-height: 20px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.Fixed .title .bg {
    z-index: -1;
    position: absolute;
    top: 30px;
    height: 260px;
    width: 260px;
    height: 100px;
    background: #000000;
    filter: blur(32px);
    border-radius: 79px;
}

.Fixed .sidebar {
    left: 0;
    top: 0;
    position: absolute;
    background-color: var(--backgroundColor);
    width: 100px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.Fixed .sidebar .menu {
    height: 150px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    z-index: 9999;
}

.Fixed .sidebar .menu li {
    left: 0;
	list-style: none;
    z-index: 9999;
}

.Fixed .sidebar .menu li a {
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    z-index: 9999;
    position: relative;

    transition: all 0.2s ease-in-out;
}

.Fixed .sidebar .menu li a:hover {
    font-size: 22px;
    font-weight: 800;
}

.Fixed .sidebar .menu li a::after {
    content: "";
    width: 0%;
    height: 3px;
    background-color: white;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transition: all 0.5s ease-in;
}

.Fixed .sidebar .menu li a:hover::after {
    width: 30%;
    left: 35%;  /* 50% - (width / 2)*/
}

.Fixed .sidebar .burgerIcon {
    display: none;
}

@media only screen and (max-width: 768px) {
    
    .Fixed .title .name {
        margin-top: 60px;

        font-size: 48px;
        line-height: 61px;        
    }
    
    .Fixed .title .secondary {
        margin-top: -8px;

        font-size: 15.8px;
        line-height: 20px;
    }
    
    .Fixed .title .bg {
        z-index: -1;
        position: absolute;
        top: 30px;
        height: 260px;
        width: 260px;
        height: 100px;
        background: #000000;
        filter: blur(32px);
        border-radius: 79px;
    }

    .Fixed .sidebar {
        background-color: transparent;
        align-items: flex-start;
    }

    .Fixed .show-menu {
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: var(--backgroundColor);
    }

    .Fixed .sidebar .menu {
        display: none;
    }

    .Fixed .sidebar .show-menu {
        display: flex;
        position: absolute;
        width: 200vw;
        height: 30%;
        background-color: var(--backgroundColor);
        align-items: center;
        justify-content: space-between;
    }

    .Fixed .sidebar .menu li {
        left: auto;
        align-self: center;
        list-style: none;
        z-index: 9999;
    }
    
    .Fixed .sidebar .menu li a {
        font-size: 28px;
        font-weight: 600;
    }

    .Fixed .sidebar .menu li a:hover {
        font-size: 32px;
        font-weight: 800;
    }

    .Fixed .sidebar .burgerIcon {
        margin-left: -20px;
        margin-top: 70px;
        display: block;
        color: white;
    }
    
    .Fixed .sidebar .burgerIcon .icon {
        width: 50px;
        height: 50px;
    }
    
    .Fixed .sidebar .menu .show-menu {
        width: fit-content;
        height: fit-content;
        position: absolute;
        display: block;
        color: white;
        top: -100px;
    }
    
    .Fixed .sidebar .menu .show-menu .icon {
        width: 50px;
        height: 50px;
    }

    .difference {
        display: none;
    }
    
}