.Mango {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-direction: column;
}

.Mango .title {
	font-size: 30px;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 50px;
}

.Mango .development {
	margin-bottom: 40px;	
}

.Mango .development .ifr {
	width: 70%;
	height: 100%;
	aspect-ratio: 16/9;
	border-radius: 10px;
}

.Mango .development .arrow {
	background-repeat: no-repeat;
	background-size: 60%;
	background-position: left 180px bottom 150px;

}

.Mango .development .main{
	width: 100%;
	height: 380px;
	background-position: center;
	border-radius: 10px;
}

.Mango .development .responsible {
	width: 100%;
	font-size: 20px;
	font-weight: 400;
	margin: 25px 0 25px 0;
}

.Mango .development .html5 {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content:flex-start;
}

.Mango .development .html5 h5 {
	font-size: 20px;
	margin-bottom: 10px;
}

.Mango .development .html5 .html5-img{
	height: 400px;
	width: 100%;
	background-position: center;
	background-size: 150%;
	background-repeat: no-repeat;
	border-radius: 10px;
}

.Mango .development .html5 h6 {
	margin-top: 10px;
	font-size: 18px;
	font-weight: 500;
	max-width: 100%;
}

.Mango .development .unity {
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 100%;
	justify-content:flex-end;
}

.Mango .development .unity h5 {
	font-size: 20px;
	margin-bottom: 10px;
}


.Mango .development .unity .unity-img{
	height: 400px;
	width: 100%;
	background-position: center;
	background-size: 120%;
	background-repeat: no-repeat;
	border-radius: 10px;
}

.Mango .development .unity h6 {
	margin-top: 10px;
	font-size: 18px;
	font-weight: 500;
	max-width: 100%;
}

.Mango .graphic .main{
	width: 100%;
	height: 380px;
	background-position: center;
	border-radius: 10px;
}

.Mango .graphic .responsible {
	width: 100%;
	font-size: 20px;
	font-weight: 400;
	margin: 25px 0 25px 0;
}