.Expertise {
	width: fit-content;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.Expertise .name {
	width: 100%;
	align-self: flex-start;
	justify-self: flex-start;
	text-align: left;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 41px;
	line-height: 50px;
	display: flex;
	margin-bottom: 20px;
	color: #FFFFFF;
}

.Expertise .box-lists{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.Expertise .box-lists .box-list {
	display: flex;
	flex-direction: row;
}

@media only screen and (max-width: 768px) {
	
	.Expertise .name {
		width: 60%;
		align-self: center;
		justify-self: center;
		text-align: center;
	}

	.Expertise .box-lists .box-list {
		flex-direction: column;
	}
}