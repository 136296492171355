.Home {
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

::selection {
	background: rgb(255, 255, 255, 0.5);
}

.Home .header {
	width: 100vw;
	display: flex;
	flex-direction: row;
	height: 95vh;
	left: 113px;
	top: 0px;
	box-shadow: inset 0px -71px 57px #000000;
}

.Home .header .img {
	width: calc((100/3)*1%);
	background-position-x: 50%;
	background-position-y: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -11;
}

@media only screen and (max-width: 768px) {

	.Home .header {
		width: 100vw;
		display: flex;
		flex-direction: column;
		height: 90vh;
		left: 113px;
		top: 0px;
		box-shadow: inset 0px -71px 57px #000000;
	}

	.Home .header .img {
		display: none;
	}

	#main {
		display: block;
		width: 100vw;
		height: 100vh;
		background-position-x: 50%;
		background-position-y: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -11;
	}
}

.Home .scrollIcon {
	margin-top: -100px;
	width: 70px;
	margin-bottom: 80px;
	filter: brightness(0) invert();
}

.Home .proffesional {
	width: 100%;
	padding-top: 120px;
	padding-bottom: 100px;
	background: linear-gradient(180deg, var(--backgroundColor) 5%, #023C41 50%, var(--backgroundColor) 100%);

	display: flex;
	flex-direction: column;
	align-items: center;
}

.Home .third {
	width: 100%;

	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	background: linear-gradient(180deg, var(--backgroundColor) 10%, #10034c 80%, rgba(16, 3, 76, 0.4) 100%);
}
