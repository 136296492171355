.Project {
	width: 304px;
	height: 212px;
	display: flex;
}

.Project .container{
	width: 100%;
	background-size: cover;
	background-position: center;
    border-radius: 14px;
	display: flex;
	flex-direction: column;
	justify-content: end;
}

.Project .container .title{
	padding: 7px;
	width: 100%;
	height: fit-content;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 14px;
	color: white;
	bottom: 0;
	font-weight: 400;
	font-size: 18px;
}

.Project a {
	text-decoration: none;

}

@media only screen and (max-width: 768px) {
	.Project {
		width: 80vw;
		height: 50vw;
		display: flex;
		margin-top: 30px;
	}

	.Project .container .title{
		font-weight: 500;
		font-size: 20px;
	}
	
}