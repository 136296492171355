details.ExperienceBox {
	max-height: 65px;
	overflow: hidden;
}

details[open].ExperienceBox {
	max-height: 500px;
	transition: all 1s ease-in-out;
}

.ExperienceBox summary {
	color: white;
	font-weight: 800;
	font-size: 18px;
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	transition: all 0.2s ease-in-out;
	background-color: rgba(46, 0, 119);;
	border-radius: 10px;

	width: 699px;
	height: 65px;
	list-style: none;
	z-index: 3;

	-webkit-user-select: none; /* Safari */        
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

details[open] summary {
	background-color: rgb(97, 0, 255);
}


.ExperienceBox .left {
	display: flex;
	flex-direction: row;
	justify-content: start;
	width: fit-content;
	align-items: center;
}

.ExperienceBox .left .icon{
	left: 0;
	width: 45px;
	margin-right: 10px;
	height: fit-content;
	border-radius: 100%;
}

.ExperienceBox .left .position {
	left: 0;
	width: fit-content;
	font-weight: 700;
	font-size: 18px;
	text-align: left;
}

.ExperienceBox .date {
	right: 0;
	font-weight: 700;
	font-size: 18px;
	text-align: right;
}

.ExperienceBox .toggle {
	width: 20px;
	filter: brightness(0) invert(1) drop-shadow(0.5px -0.5px 0px white);
}



@media only screen and (max-width: 768px) {
	.ExperienceBox summary {
		font-size: 18px;

		width: 80vw;
		height: 65px;
	}
	
	details[open].ExperienceBox {
		max-height: 85vh;
	}
}