.Proffesional {
	width: fit-content;
	margin-top: 150px;
	display: flex;
	justify-content: start;
	align-items:flex-start;
	flex-direction: column;
}

.Proffesional .name {
	width: 100%;
	align-self: flex-start;
	justify-self: flex-start;
	text-align: start;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 41px;
	line-height: 50px;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	
	color: #FFFFFF;
}

@media only screen and (max-width: 768px) {
	.Proffesional {
		justify-content: center;
		align-items: center;
		width: 80vw;
	}
	
	.Proffesional .name {
		align-self: center;
		justify-self: center;
		text-align: center;		
	}
}