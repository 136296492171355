.Experience {
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
	.Experience {
		justify-content: center;
		align-items: center;
	}
}